<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
		<!-- [D] 가로/세로 가운데 정렬 레이어 일 경우 layer__wrap--centered 클래스 추가 -->
		<div class="layer__wrap layer__wrap--centered">
			<div class="layer-message">
				<div class="layer-message__top">
					<h3 class="layer-message__title">
						신고가 완료되었습니다.
					</h3>
				</div>
				<div class="layer-message__btns">
					<button type="button" class="btn skyblue">
						확인
					</button>
				</div>
			</div>
		</div>
	</section>
</template>